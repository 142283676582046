import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import './QuadrilateralOverlay.scss';

interface Point {
    coordinateX: number; // Percentage for internal usage (0.0 to 1.0)
    coordinateY: number; // Percentage for internal usage (0.0 to 1.0)
}

interface IQuadrilateralOverlayProps {
    mediaUrl: string;
    isVideo: boolean;
    isIframe?: boolean;
    showLines?: boolean;
    showPoints?: boolean;
    canDrag: boolean;
    initialPoints?: { coordinateX: string; coordinateY: string }[] | null; // Percentages as strings
    onPointsChange?: (points: { coordinateX: string; coordinateY: string }[]) => void; // Pass percentages as strings to the parent
    overlayColor?: 'red' | 'gray'; // New prop for overlay color
    customComponent?: ReactNode;
}

// Default points defined
export const defaultPoints: { coordinateX: string; coordinateY: string }[] = [
    // { coordinateX: "0.1", coordinateY: "0.05" },
    // { coordinateX: "0.3", coordinateY: "0.05" },
    // { coordinateX: "0.3", coordinateY: "0.2" },
    // { coordinateX: "0.1", coordinateY: "0.2" },
    { coordinateX: "0.3", coordinateY: "0.3" },
    { coordinateX: "0.7", coordinateY: "0.3" },
    { coordinateX: "0.7", coordinateY: "0.7" },
    { coordinateX: "0.3", coordinateY: "0.7" },
];

// Styled component for the filled quadrilateral
const FilledQuadrilateral = styled.div<{ points: Point[]; color: string }>`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ color }) => (color === 'red' ? 'rgba(255, 0, 0, 0.5)' : 'rgba(128, 128, 128, 0.5)')};
    clip-path: ${({ points }) =>
        `polygon(${points.map((point) => `${point.coordinateX * 100}% ${point.coordinateY * 100}%`).join(', ')})`};
    pointer-events: none;
`;

// Styled component for the transparent layer above the media
const TransparentLayer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 2; /* Make sure the layer is above the media */
`;

// Styled component for point markers
const PointMarker = styled.div`
    position: absolute;
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */
    background-color: blue; /* Color of the point */
    border-radius: 0%; /* Circular shape */
    transform: translate(-50%, -50%); /* Center the point */
    cursor: grab;
    z-index: 3; /* Above the transparent layer */
`;

// Styled component for lines connecting points
const Line = styled.div<{ length: number; angle: number; startX: number; startY: number; }>`
    position: absolute;
    background-color: red; /* Color of the line */
    transform-origin: top left;
    z-index: 1; /* Below the points and quadrilateral */
    height: 2px; /* Line thickness */
    width: ${({ length }) => `${length}%`}; /* Line length as a percentage */
    left: ${({ startX }) => `${startX}%`};  /* Start position as percentage */
    top: ${({ startY }) => `${startY}%`};    /* Start position as percentage */
    transform: rotate(${({ angle }) => `${angle}deg`}); /* Rotate based on angle */
`;

const QuadrilateralOverlay: React.FC<IQuadrilateralOverlayProps> = ({
    mediaUrl,
    isVideo,
    isIframe = false,
    showLines = true,
    showPoints = true,
    canDrag,
    initialPoints = null,
    onPointsChange,
    overlayColor = 'red', // Default color
    customComponent,
}) => {
    const [points, setPoints] = useState<Point[]>(() => {
        if (initialPoints) {
            return initialPoints.map(point => ({
                coordinateX: parseFloat(point.coordinateX),
                coordinateY: parseFloat(point.coordinateY),
            }));
        }
        return defaultPoints.map(point => ({
            coordinateX: parseFloat(point.coordinateX),
            coordinateY: parseFloat(point.coordinateY),
        }));
    });

    const [draggingIndex, setDraggingIndex] = useState<number | null>(null);
    const [dragOffset, setDragOffset] = useState<{ coordinateX: number; coordinateY: number } | null>(null);

    const updateParentPoints = (updatedPoints: Point[]) => {
        const pointsAsPercentages = updatedPoints.map(point => ({
            coordinateX: point.coordinateX.toFixed(3),
            coordinateY: point.coordinateY.toFixed(3),
        }));

        if (onPointsChange) {
            onPointsChange(pointsAsPercentages);
        }
    };

    useEffect(() => {
        if (initialPoints) {
            const pointsInPercentage = initialPoints.map(point => ({
                coordinateX: parseFloat(point.coordinateX),
                coordinateY: parseFloat(point.coordinateY),
            }));
            setPoints(pointsInPercentage);
        }
    }, [initialPoints]);

    useEffect(() => {
        const handleMouseMove = (e: MouseEvent) => {
            if (canDrag && draggingIndex !== null && dragOffset) {
                const container = document.querySelector('.quadrilateral-overlay-container') as HTMLElement;
                const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

                const newCoordinateX = Math.max(0, (e.clientX - dragOffset.coordinateX) / containerWidth);
                const newCoordinateY = Math.max(0, (e.clientY - dragOffset.coordinateY) / containerHeight);

                const newPoints = [...points];
                newPoints[draggingIndex] = {
                    coordinateX: Math.min(1, newCoordinateX),
                    coordinateY: Math.min(1, newCoordinateY),
                };
                setPoints(newPoints);
            }
        };

        const handleMouseUp = () => {
            if (draggingIndex !== null) {
                setDraggingIndex(null);
                setDragOffset(null);
                document.body.style.cursor = 'default';

                // Update parent with percentage-based points
                updateParentPoints(points);
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [canDrag, draggingIndex, points, dragOffset]);

    const handleMouseDown = (index: number) => (e: React.MouseEvent) => {
        if (canDrag) {
            e.preventDefault();
            const container = document.querySelector('.quadrilateral-overlay-container') as HTMLElement;
            const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
            const point = points[index];

            // Calculate the drag offset in pixels based on the current point's percentage
            setDragOffset({
                coordinateX: e.clientX - point.coordinateX * containerWidth,
                coordinateY: e.clientY - point.coordinateY * containerHeight,
            });

            setDraggingIndex(index);
            document.body.style.cursor = 'grabbing';
        }
    };

    const renderLines = () => {
        const lines: JSX.Element[] = [];
        const container = document.querySelector('.quadrilateral-overlay-container') as HTMLElement;
        if (!container) {
            return;
        }
        const { width: containerWidth, height: containerHeight } = container?.getBoundingClientRect();

        for (let i = 0; i < points.length; i++) {
            const startPoint = points[i];
            const endPoint = points[(i + 1) % points.length]; // Wrap around to the first point

            const startX = startPoint.coordinateX * containerWidth;
            const startY = startPoint.coordinateY * containerHeight;
            const endX = endPoint.coordinateX * containerWidth;
            const endY = endPoint.coordinateY * containerHeight;

            // Calculate the length of the line in pixels
            const length = Math.sqrt(Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2));

            // Calculate the angle in degrees
            const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI);

            // Convert length to a percentage of the container width
            const lengthPercentage = (length / containerWidth) * 100;

            lines.push(
                <Line key={`line-${i}`} length={lengthPercentage} angle={angle} startX={startPoint.coordinateX * 100} startY={startPoint.coordinateY * 100} />
            );
        }

        return lines;
    };

    const renderPoints = () => {
        return points.map((point, index) => (
            <PointMarker
                key={index}
                style={{
                    left: `${point.coordinateX * 100}%`,
                    top: `${point.coordinateY * 100}%`,
                    cursor: canDrag ? 'grab' : 'default',
                }}
                onMouseDown={handleMouseDown(index)}
            />
        ));
    };

    return (
        <div className="quadrilateral-overlay-container" style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
            {showLines && (
                <>
                    {renderLines()}
                    <FilledQuadrilateral points={points} color={overlayColor} />
                </>
            )}
            <TransparentLayer>
                {showPoints && renderPoints()}
            </TransparentLayer>
            {/* {isIframe && (
                <iframe src={mediaUrl} title="Media" style={{ width: '100%', height: '100%', border: 'none', pointerEvents: 'none' }} />
            )}
            {!isIframe && isVideo && (
                <video
                    src={mediaUrl}
                    style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
                    autoPlay
                    muted
                    loop
                />
            )}
            {!isIframe && !isVideo && (
                <img src={mediaUrl} alt="Overlay media" style={{ width: '100%', height: '100%', pointerEvents: 'none' }} />
            )} */}

            {customComponent ? (
                customComponent
            ) : isIframe ? (
                <iframe src={mediaUrl} title="Media" style={{ width: '100%', height: '100%', border: 'none', pointerEvents: 'none' }} />
            ) : isVideo ? (
                <video src={mediaUrl} style={{ width: '100%', height: '100%', pointerEvents: 'none' }} autoPlay muted loop />
            ) : (
                <img src={mediaUrl} alt="Overlay media" style={{ width: '100%', height: '100%', pointerEvents: 'none' }} />
            )}
        </div>
    );
};

export default QuadrilateralOverlay;
