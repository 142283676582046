import React, { useState, useEffect } from 'react';
import './CustomCheckboxComponent.scss';

interface CustomCheckboxProps {
    checked?: boolean; // Make this optional for default value
    onChange?: (checked: boolean) => void;
    disabled?: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked = false, onChange, disabled = false }) => {

    const handleChange = () => {
        if (!disabled && onChange) {
            onChange(!checked); // Simply toggle the current value
        }
    };

    return (
        <label className={`checkbox-container ${disabled ? 'disabled' : ''}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
                readOnly
            />
            <span className="custom-checkbox"></span>
        </label>
    );
};

export default CustomCheckbox;
