import CustomButton from '../CustomButton/CustomButtonComponent';
import SvgIcon from '../SvgIcons/SvgIconComponent';
import ThreeLineLoader from '../ThreeLineLoader/ThreeLineLoader';
import './AlertBoxComponent.scss';

interface IProps {
    headerLabel: string;
    subHeaderLabel?: string;
    subHeaderIcon?: any;
    footerLabel?: string;
    timestamp?: string;
    thumbnails?: {
        video?: string;
        image?: string;
    };
    severityLevel?: string;
    showStatus: boolean;
    showActionSection?: boolean;
    showViewDetailsBtn?: boolean;
    isAlertResolved?: boolean | null;
    showMediaThumbnail?: boolean;
    showInPopupDesign?: boolean;
    showCloseBtn?: boolean;
    onAlertViewDetailsClick?: () => void;
    onResolveBtnClick?: () => void;
    onDeleteBtnClick?: () => void;
    onCloseBtnClick?: () => void;
    onImageThumbnailClick?: () => void;
    onVideoThumbnailClick?: () => void;
}

const AlertBox: React.FC<IProps> = ({
    headerLabel = '',
    subHeaderLabel = '',
    subHeaderIcon,
    footerLabel = '',
    timestamp = '',
    thumbnails,
    severityLevel = '',
    showInPopupDesign = false,
    showStatus = false,
    showActionSection = false,
    showViewDetailsBtn = false,
    isAlertResolved = null,
    showMediaThumbnail = true,
    showCloseBtn = false,
    onAlertViewDetailsClick,
    onResolveBtnClick,
    onDeleteBtnClick,
    onCloseBtnClick,
    onImageThumbnailClick,
    onVideoThumbnailClick,
}) => {

    const renderThumbnail = () => {
        return (
            (showMediaThumbnail && (thumbnails?.video || thumbnails?.image)) ?
                (
                    <aside className="thumbnails">
                        {/* {
                            thumbnails?.image ? (
                                <figure onClick={onImageThumbnailClick}>
                                    <img src={thumbnails?.image} alt="" />
                                </figure>
                            ) : ''
                        } */}
                        {
                            thumbnails?.video ? (
                                <figure className='play-icon' onClick={onVideoThumbnailClick}>
                                    {/* <img src={thumbnails?.video} alt="" /> */}
                                    <video src={thumbnails?.video} />
                                </figure>
                            ) : (
                                // <div className='loader'>
                                //     <ThreeLineLoader />
                                // </div>
                                ''
                            )
                        }
                    </aside>
                ) : ''
        )
    }

    const renderActions = () => {
        return (
            <section className={`actions ${showActionSection ? 'show' : ''}`}>

                <div className="left-actions">
                    <CustomButton
                        // className={isAlertResolved ? 'resolved' : 'unresolved'}
                        variant="contained"
                        btnType='success'
                        disabled={!!isAlertResolved}
                        icon={<SvgIcon name='reset' width={16} height={16} />}
                        onClick={onResolveBtnClick}
                    />
                    <CustomButton
                        variant="contained"
                        btnType='error'
                        icon={<SvgIcon name='close' width={16} height={16} />}
                        onClick={onDeleteBtnClick}
                    />
                </div>

                {
                    showViewDetailsBtn ? (
                        <aside className="right-actions">
                            <CustomButton
                                text="View Details"
                                variant="contained"
                                onClick={onAlertViewDetailsClick}
                            />
                        </aside>

                    ) : ''
                }
            </section>
        );
    }

    const renderFooter = () => {
        if (showInPopupDesign && !showActionSection) {
            return '';
        }
        return (
            <>
                <footer>
                    <div className="label-container">
                        <label>Cam ID - Camera #{footerLabel}</label>
                        {
                            showInPopupDesign && showActionSection && (<time>{timestamp}</time>)
                        }
                    </div>
                    {
                        showInPopupDesign ? (<span>{severityLevel}</span>) : (<time>{timestamp}</time>)
                    }
                </footer>
            </>
        );
    }

    return (
        <section className={`alert-box ${showInPopupDesign && 'popup-design'}`}>
            {
                showCloseBtn &&
                (<CustomButton
                    className='alert-box__close'
                    icon={<SvgIcon name='close' width={16} height={16} />}
                    onClick={onCloseBtnClick}
                />)
            }
            <header className='alert-box__header'>
                <section className="main-and-subheader">
                    <h4 className={`main-heading`} title={headerLabel}>
                        {
                            (isAlertResolved !== null && showStatus) && (
                                <mark className={isAlertResolved ? 'resolved' : 'unresolved'}></mark>
                            )
                        }
                        <label>
                            {headerLabel}
                        </label>
                    </h4>
                    <h6 className='sub-heading'>
                        <figure>
                            {subHeaderIcon ? subHeaderIcon : <></>}
                            <figcaption>{subHeaderLabel}</figcaption>
                        </figure>
                        {
                            showInPopupDesign && !showActionSection && (<time>{timestamp}</time>)
                        }
                    </h6>
                </section>

                {renderThumbnail()}

            </header>

            {renderActions()}

            <hr />

            {renderFooter()}
        </section>
    )
}

export default AlertBox;