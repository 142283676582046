import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CustomButton from '../../components/CustomButton/CustomButtonComponent';
import SvgIcon from '../../components/SvgIcons/SvgIconComponent';

const drawerWidth = 360;

interface MainLayoutProps {
    window?: () => Window;
    listPosition: "left" | "right";
    listContent: React.ReactNode; // Dynamic content for the scrollable list
    mainContent: React.ReactNode; // Dynamic content for the main section
    showSlideSideContainerBtn?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = (props) => {
    const { window, listContent, mainContent, listPosition, showSlideSideContainerBtn = false } = props;
    const [mobileOpen, setMobileOpen] = React.useState<boolean>(false);
    const [isClosing, setIsClosing] = React.useState<boolean>(false);
    const [isDrawerHidden, setIsDrawerHidden] = React.useState<boolean>(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const handleDrawerHideToggle = () => {
        console.log(isDrawerHidden);
        setIsDrawerHidden(!isDrawerHidden); // Toggle drawer visibility
    };

    const drawer = (
        <>
            {listContent}
        </>
    );

    // Remove this const when copying and pasting into your project.
    const container = window !== undefined ? window().document.body : undefined;

    return (
        <Box sx={{
            display: `flex`,
            height: '100%',
            overflow: `hidden`
        }}>
            <CssBaseline />

            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
            >
                <MenuIcon />
            </IconButton>
            {
                (listPosition !== 'left') ? (
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 0,
                            // width: { sm: `calc(100% - ${drawerWidth}px)` }
                            width: { sm: `calc(100% - ${isDrawerHidden ? 0 : drawerWidth}px)` }
                        }}
                    >
                        {mainContent}
                    </Box>
                ) : ''
            }
            {/* <Box
                component="nav"
                sx={{
                    width: { sm: drawerWidth },
                    flexShrink: { sm: 0 },
                    transition: 'transform 0.3s ease',
                    transform: isDrawerHidden ? `translateX(${drawerWidth}px)` : `translateX(0)`
                }}
                aria-label="mailbox folders"
            > */}
            <nav
                style={{
                    width: `${drawerWidth}px`,
                    transition: 'transform 0.3s ease',
                    transform: isDrawerHidden ? `translateX(${listPosition == 'left' ? '-' : ''}${drawerWidth}px)` : `translateX(0)`,
                    flex: isDrawerHidden ? `1` : `none`
                }}
            >

                {/* <Drawer
                    container={container}
                    variant="temporary"
                    anchor={listPosition}
                    open={mobileOpen}
                    onTransitionEnd={handleDrawerTransitionEnd}
                    onClose={handleDrawerClose}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer> */}
                <Drawer
                    variant="permanent"
                    anchor={listPosition}
                    sx={{
                        top: 'unset',
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            top: 'unset',
                            // height: 'calc(100% - (64px + 24px))',
                            overflow: 'initial',
                        }
                    }}
                    open
                >
                    {
                        showSlideSideContainerBtn &&
                        (<CustomButton
                            className='floating-sliding-btn'
                            icon={<SvgIcon name={'arrowRight'} />}
                            style={{
                                width: `20px`,
                                height: `20px`,
                                position: 'absolute',
                                top: `44px`,
                                transform: `${isDrawerHidden ? 'rotate(180deg)' : 'rotate(0deg)'}`,
                                // left: listPosition === 'left' ? '-12px' : undefined, // Adjust based on list position
                                // right: listPosition === 'right' ? '-12px' : undefined,
                                // left: `-12px`,
                                left: listPosition === 'left' ? `unset` : `-12px`,
                                right: listPosition === 'right' ? `unset` : `-12px`,
                                backgroundColor: '#68b5c7',
                                transition: `all .3s ease-in-out`
                            }}
                            onClick={handleDrawerHideToggle}
                        />)
                    }
                    {drawer}
                </Drawer>
            </nav>
            {/* </Box> */}

            {
                (listPosition !== 'right') ? (
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            p: 0,
                            width: isDrawerHidden ? '100%' : `calc(100% - ${drawerWidth}px)`
                        }}
                    >
                        {mainContent}
                    </Box>
                ) : ''
            }

        </Box>
    );
};

export default MainLayout;
