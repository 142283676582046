import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CameraList from "../../components/CameraList/CameraListComponent";
import ConfigAction from "../../components/ConfigAction/ConfigActionComponent";
import ToastService from "../../components/Toast/ToastService";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { deleteCamera, setCameraList } from "../../sdk/redux/slices/cameraSlice";
import { RootState } from "../../sdk/redux/store/store";
import cameraConfigService from "../../sdk/services/cameraConfigService";
import { CameraConfigType } from "../../sdk/types/cameraConfig.type";
import './ConfigurationsPage.scss';


const ConfigurationsPage: React.FC = () => {
    const dispatch = useDispatch();
    const cameraList = useSelector((state: RootState) => state.camera.cameraList);
    const [selectedCamera, setSelectedCamera] = useState<CameraConfigType | null>(null);

    useEffect(() => {
        const fetchCameraList = async () => {
            try {
                const list: CameraConfigType[] = await cameraConfigService.getAllCameraList();
                dispatch(setCameraList(list));
                // setSelectedCamera(list[0] || null);
            } catch (error) {
                console.error(error);
            } finally {
                console.log('API completed');
            }
        }

        fetchCameraList();
    }, [dispatch]);

    useEffect(() => {
        if (cameraList?.length) {
            const findCamera = cameraList.find((cam: CameraConfigType) => cam.id == selectedCamera?.id);
            if (!findCamera) {
                setSelectedCamera(cameraList[0]);
            }
        } else {
            setSelectedCamera(null);
        }
    }, [cameraList])

    const handleUpdateSelectedCamera = async (selected: CameraConfigType) => {
        try {
            const cameraDetails = await cameraConfigService.getCameraById((selected?.id || '')?.toString());
            if (cameraDetails) {
                setSelectedCamera(cameraDetails);
            }
        } catch (error) {
            console.error("Error fetching camera details:", error);
        }
    }

    const handleDeleteCamera = async (id: string) => {
        try {
            await cameraConfigService.deleteCameraById(id);
            dispatch(deleteCamera(id));
            ToastService.showToast('Delete camera', `Camera deleted successfully`, 'success', 3000);
        } catch (error: any) {
            const { errorCode, errorInfo } = error?.response?.data;
            let errorMessage = errorInfo || 'Something went wrong';
            ToastService.showToast('Login failed', errorMessage, 'error');
        }
    }

    const handleOnCameraAddedEvent = (data: CameraConfigType) => {
        setSelectedCamera(data);
    }

    const listContent = (
        <CameraList
            list={cameraList}
            selectedCamera={selectedCamera}
            updateSelectedCamera={handleUpdateSelectedCamera}
            onCameraAddedEvent={handleOnCameraAddedEvent}
            deleteCamera={handleDeleteCamera}
        />
    );

    const mainContent = (
        <ConfigAction selectedCamera={selectedCamera} />
    );


    return (
        <section className="configurations-page">
            <MainLayout listPosition="left" listContent={listContent} mainContent={mainContent} />
        </section>
    );
}

export default ConfigurationsPage;