import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertBox from '../../components/AlertBox/AlertBoxComponent';
import CameraView from '../../components/CameraView/CameraViewComponent';
import CustomDialog from '../../components/CustomDialog/CustomDialogComponent';
import useCustomDialogHandler from '../../components/CustomDialog/useCustomDialogHandler';
import EventCountBar, { IEventCounter } from '../../components/EventCountBar/EventCountBarComponent';
import ExpandableAlertList from '../../components/ExpandableAlertList/ExpandableAlertList';
import IncedentFeedAndLocation from '../../components/IncedentFeedAndLocation/IncedentFeedAndLocationComponent';
import SvgIcon from '../../components/SvgIcons/SvgIconComponent';
import ZoomablePage, { ZoomablePageMethods } from '../../components/zoomable/ZoomablePage';
import MainLayout from '../../layouts/MainLayout/MainLayout';
import { deleteAlert, selectAllAlerts, selectLatestAlertByCameraId, selectResolvedAlerts, selectUnresolvedAlerts, setAlertList, updateAlert } from '../../sdk/redux/slices/alertSlice';
import alertService from '../../sdk/services/alertService';
import { AlertDetailsType, BannerDetailsType } from '../../sdk/types/alert.type';
import { CameraDetailsInFloorMapType } from '../../sdk/types/cameraConfig.type';
import { formatTimestamp, getLatestAlertByCameraId, scaleToPercentage, updateBannerCountList } from '../../utils/common.util';
import './AlertsPage.scss';
import ToastService from '../../components/Toast/ToastService';
import { RootState } from '../../sdk/redux/store/store';
import DeleteAlertDialog from '../../components/DeleteAlertDialog/DeleteAlertDialog';
import CustomButton from '../../components/CustomButton/CustomButtonComponent';
import Pin from '../../components/Pin/PinComponent';

const AlertsPage: React.FC = () => {
    const dispatch = useDispatch();
    const [showType, setShowType] = useState<'all' | 'resolved' | 'unresolved'>('all');
    const [cameraListDetails, setCameraListDetails] = useState<CameraDetailsInFloorMapType[]>([]);
    const [selectedCamView, setSelectedCamView] = useState<string>('');
    const [selectedAlert, setSelectedAlert] = useState<AlertDetailsType | null>(null);
    const [bannerCountList, setBannerCountList] = useState<IEventCounter[]>([]);
    const [incidentToDelete, setIncidentToDelete] = useState<AlertDetailsType | null>(null);

    const [showAlertOnCamView, setShowAlertOnCamView] = useState<boolean>(true);
    const [selectedAlertToShowOnCamView, setSelectedAlertToShowOnCamView] = useState<AlertDetailsType | null>(null);

    const alertList = useSelector((state: RootState) => state.alert.alertList);
    const resolvedAlerts = useSelector(selectResolvedAlerts);
    const unresolvedAlerts = useSelector(selectUnresolvedAlerts);
    const allAlerts = useSelector(selectAllAlerts);

    const { openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog } = useCustomDialogHandler();
    const pageRef = useRef<HTMLDivElement>(null);
    const zoomablePageRef = useRef<ZoomablePageMethods>(null);

    useEffect(() => {
        handleGetFloorMapCameraDetails(true);
    }, [dispatch]);

    // Function to call the API
    const fetchData = useCallback(async () => {
        handleGetFloorMapCameraDetails();
    }, []);

    // API call on page load and every 2 seconds
    useEffect(() => {
        fetchData(); // Call on page load

        const intervalId = setInterval(() => {
            fetchData(); // Call every 5 seconds
        }, 5000);

        return () => clearInterval(intervalId); // Clear the interval when the component unmounts
    }, [fetchData]);

    const handleGetFloorMapCameraDetails = async (showLoader: boolean = false) => {
        try {
            alertService.getAllFloorMapCameraDetails(showLoader).then((floorMapCameraList: CameraDetailsInFloorMapType[]) => {
                setCameraListDetails(floorMapCameraList);
            })
            alertService.getAllAlerts(showLoader).then((alertList: AlertDetailsType[]) => {
                dispatch(setAlertList(alertList));
            })
            alertService.getAlertBannerCountList(showLoader).then((alertBannerCountListResponse: BannerDetailsType[]) => {
                setBannerCountList(updateBannerCountList(alertBannerCountListResponse));
            })
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleAlertViewDetailsClick = useCallback((alert: AlertDetailsType) => {
        setSelectedAlert(alert);
        handleOpenDialog();
    }, [handleOpenDialog]);

    const handleAlertToExpandInListView = (alert: AlertDetailsType) => {
        setSelectedCamView(prev => (alert?.camera?.cameraId || ''));
        setSelectedAlertToShowOnCamView(alert);
        setShowAlertOnCamView(true);
    }

    const handleCameraViewPinClick = (cam: CameraDetailsInFloorMapType) => {
        setShowAlertOnCamView(true);
        setSelectedCamView(prev => (cam?.cameraId || ''));
        if (cam?.cameraId) {
            const latestAlert = getLatestAlertByCameraId(cam?.cameraId, allAlerts);
            setSelectedAlertToShowOnCamView(latestAlert);
        }
    }

    const triggerResolveAlert = async (alert: AlertDetailsType) => {
        try {
            const updatedResult = await alertService.updateAlert(alert.id, true, alert.deleted);
            if (updatedResult) {
                dispatch(updateAlert({ id: alert.id, updatedAlertResult: updatedResult }));
                ToastService.showToast('Resolve alert', `Alert resolved successfully`, 'success', 3000);
            }
        } catch (error: any) {
            const { errorCode, errorInfo } = error?.response?.data;
            let errorMessage = errorInfo || 'Something went wrong';
            ToastService.showToast('Resolve alert', errorMessage, 'error');
        }
    }

    const showDeleteAlertPopup = (alert: AlertDetailsType) => {
        setIncidentToDelete(alert);
    }

    const triggerDeleteAlert = async () => {
        if (incidentToDelete) {
            try {
                await alertService.updateAlert(incidentToDelete?.id, incidentToDelete?.resolved, true);
                handleCloseDialog();
                if (selectedAlertToShowOnCamView?.id == incidentToDelete?.id) {
                    setSelectedAlertToShowOnCamView(null);
                    setSelectedCamView('');
                }
                dispatch(deleteAlert(incidentToDelete.id));
                ToastService.showToast('Delete alert', `Alert deleted successfully`, 'success', 3000);
                setIncidentToDelete(null);
                fetchData();
            } catch (error: any) {
                const { errorCode, errorInfo } = error?.response?.data;
                let errorMessage = errorInfo || 'Something went wrong';
                ToastService.showToast('Delete alert', errorMessage, 'error');
            }
        }
    }

    const alertsToDisplay = useMemo(() => {
        switch (showType) {
            case 'resolved':
                return resolvedAlerts;
            case 'unresolved':
                return unresolvedAlerts;
            default:
                return allAlerts;
        }
    }, [showType, resolvedAlerts, unresolvedAlerts, allAlerts]);

    const handleZoomIn = () => {
        zoomablePageRef.current?.handleZoomIn();
    };

    const handleZoomOut = () => {
        zoomablePageRef.current?.handleZoomOut();
    };

    const handleReset = () => {
        zoomablePageRef.current?.handleReset();
    };

    const dialogContent = () => {
        if (selectedAlert == null) {
            return '';
        }
        const alertToShow = allAlerts.find((alrt: AlertDetailsType) => alrt.id == selectedAlert?.id) || selectedAlert;
        const filteredList = allAlerts?.filter((alert: AlertDetailsType) => alert?.camera?.cameraId === selectedAlert?.camera?.cameraId);
        return (
            <section className="dialog-content">
                <aside className='side-content'>
                    <AlertBox
                        headerLabel={alertToShow?.alertName || ''}
                        subHeaderLabel={alertToShow?.camera?.location}
                        subHeaderIcon={<SvgIcon name='location' />}
                        footerLabel={alertToShow?.camera?.cameraId}
                        timestamp={formatTimestamp(alertToShow?.alertTimestamp || 0)}
                        showActionSection={true}
                        isAlertResolved={alertToShow?.resolved}
                        showInPopupDesign={true}
                        showStatus={true}
                        severityLevel={'Severity Level 1'}
                        showMediaThumbnail={false}
                        onResolveBtnClick={() => triggerResolveAlert(alertToShow)}
                        onDeleteBtnClick={() => showDeleteAlertPopup(alertToShow)}
                    />

                    <h4>{`Camera #${selectedAlert?.camera.cameraId} Past Alerts`}</h4>
                    <section className="past-alert-container">
                        <ul className='past-alerts'>
                            {
                                filteredList.map((alert: AlertDetailsType, index: number) => (
                                    <li key={alert.id} className={`alert-info-item`}>
                                        <header>
                                            <h3>{alert.alertName}</h3>
                                            <mark className={`${alert.resolved ? 'resolved' : 'unresolved'}`}></mark>
                                        </header>
                                        <footer>
                                            <figure>
                                                <SvgIcon name='location' />
                                                <figcaption>{alert.camera.location}</figcaption>
                                            </figure>
                                            <time>{formatTimestamp(alert?.alertTimestamp || 0)}</time>
                                        </footer>
                                    </li>
                                ))
                            }
                        </ul>
                    </section>
                </aside>
                <div className="main-content">
                    <IncedentFeedAndLocation alertDetails={selectedAlert || {} as AlertDetailsType} />
                </div>
            </section>
        );
    };

    const handleAlertBoxCloseBtnClick = () => {
        console.log('test');
        setShowAlertOnCamView(false);
    }

    const alertBoxRenderer = useMemo(() => {
        const alertToShow = allAlerts.find((alrt: AlertDetailsType) => alrt.id == selectedAlertToShowOnCamView?.id);
        if (!alertToShow) {
            return;
        }
        return (
            <>
                <AlertBox
                    headerLabel={alertToShow?.alertName} //"Unattended"
                    subHeaderLabel={alertToShow?.camera?.location}
                    subHeaderIcon={<SvgIcon name='location' />}
                    footerLabel={alertToShow?.camera?.cameraId}
                    timestamp={formatTimestamp(alertToShow?.alertTimestamp || 0)}
                    showStatus={true}
                    showActionSection={true}
                    showViewDetailsBtn={true}
                    isAlertResolved={alertToShow?.resolved}
                    showCloseBtn={true}
                    thumbnails={{
                        image: alertToShow?.images?.length ? alertToShow?.images[0] : '',
                        video: alertToShow?.videos[0] || '',
                    }}
                    onAlertViewDetailsClick={() => handleAlertViewDetailsClick(alertToShow)}
                    onResolveBtnClick={() => triggerResolveAlert(alertToShow)}
                    onDeleteBtnClick={() => showDeleteAlertPopup(alertToShow)}
                    onCloseBtnClick={() => handleAlertBoxCloseBtnClick()}
                    onImageThumbnailClick={() => handleAlertViewDetailsClick(alertToShow)}
                    onVideoThumbnailClick={() => handleAlertViewDetailsClick(alertToShow)}
                />
            </>
        );
    }, [selectedCamView, alertsToDisplay, selectedAlertToShowOnCamView, allAlerts]);

    const toggleResolved = () => {
        setShowType(prev => prev == 'all' ? 'resolved' : (prev == 'resolved' ? 'unresolved' : 'all'));
    }

    const getAlertsByCameraId = (cameraId: string | undefined) => {
        if (!cameraId) {
            return [];
        }
        return allAlerts.filter(alert => alert.camera?.cameraId === cameraId);
    };

    const dynamicComponentList = useMemo(() => {
        return cameraListDetails?.map((cam: CameraDetailsInFloorMapType, index: number) => {
            // const totalAlerts = cam?.totalAlerts ? getAlertsByCameraId(cam.cameraId).length : 0;
            const totalAlerts = cam?.totalAlerts || 0;
            let alertCount = (totalAlerts > 999 ? `${totalAlerts.toString().charAt(0)}K+` : totalAlerts);
            // alertCount = `1K+`; // TODO: Temp code. Will be removed soon.
            return {
                component: (
                    <>
                        <section className="alert-box-and-cam-view">
                            <section className='alert-box-container' onClick={(e) => e.stopPropagation()}>
                                {(selectedAlertToShowOnCamView?.camera?.cameraId == cam?.cameraId) && showAlertOnCamView && alertBoxRenderer}
                            </section>
                            <div onClick={(e) => setSelectedCamView('')}>
                                {/* onClick={(e) => e.stopPropagation()} */}
                                <CameraView
                                    cameraAngle={cam?.cameraAngle}
                                    fieldOfView={cam?.fieldOfView}
                                    showPin={true}
                                    // pinTxt={`${totalAlerts}`}
                                    pinTxt={`${alertCount}`}
                                    onPinClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                        event.stopPropagation();
                                        if (totalAlerts == 0) {
                                            return;
                                        }
                                        handleCameraViewPinClick(cam);
                                    }}
                                />
                            </div>
                        </section>
                    </>
                ),  // assuming cameraId is unique
                position: { x: scaleToPercentage(cam.coordinateX), y: scaleToPercentage(cam.coordinateY) },
                increaseZIndex: selectedAlertToShowOnCamView?.camera?.cameraId == cam?.cameraId || false,
                id: cam.cameraId,
            }
        })
    }, [cameraListDetails, selectedCamView, alertBoxRenderer, allAlerts, showAlertOnCamView]);

    const mainContent = (
        <div ref={pageRef} className='alert-main-content'>
            <br />
            <EventCountBar countList={bannerCountList} />

            <div onClick={(e) => e.stopPropagation()}>
                <CustomDialog
                    headerLabel={`Cam ID - ${selectedAlert?.camera?.cameraId} - ${selectedAlert?.alertName || ''}`}
                    open={openDialog}
                    content={dialogContent()}
                    dialogMaxWidth='lg'
                    showBottomActionBlock={false}
                    onClose={handleCloseDialog}
                    onCancel={handleCancelDialog}
                    onSave={handleSaveDialog}
                />
            </div>

            <br />

            <section className="floormap-block">
                <figure className="floormap-container">
                    <ZoomablePage
                        ref={zoomablePageRef}
                        components={dynamicComponentList}
                        showZoomControls={false}
                        isImageDraggable={true}
                        isCamCreationAllowed={false}
                    />
                </figure>
                <footer className="controls">
                    <CustomButton
                        icon={<SvgIcon name='zoomIn' width={16} height={16} />}
                        variant='contained'
                        onClick={handleZoomIn} />
                    <CustomButton
                        icon={<SvgIcon name='zoomOut' width={16} height={16} />}
                        variant='contained'
                        onClick={handleZoomOut} />
                    <CustomButton
                        icon={<SvgIcon name='maximize' width={16} height={16} />}
                        variant='contained'
                        onClick={handleReset} />
                </footer>
            </section>

        </div>
    );

    return (
        <>
            <section className='alerts-page' onClick={(e) => setSelectedCamView('')}>
                <MainLayout
                    listPosition="right"
                    showSlideSideContainerBtn={true}
                    listContent={
                        <ExpandableAlertList
                            setFilterBtnClassName={showType}
                            items={alertsToDisplay}
                            showInPopupDesign={false}
                            showStatus={true}
                            alertToShowViewDetails={selectedAlertToShowOnCamView}
                            onSelectingAlertToShowAction={handleAlertToExpandInListView}
                            onAlertViewDetailsClick={handleAlertViewDetailsClick}
                            onResolveBtnClick={triggerResolveAlert}
                            onDeleteBtnClick={showDeleteAlertPopup}
                            onToggleResolveIconClick={toggleResolved}
                            onImageThumbnailClick={handleAlertViewDetailsClick}
                            onVideoThumbnailClick={handleAlertViewDetailsClick}
                        />
                    }
                    mainContent={mainContent}
                />
            </section>

            <DeleteAlertDialog
                open={!!incidentToDelete}
                onClose={() => setIncidentToDelete(null)}
                onCancel={() => setIncidentToDelete(null)}
                onSave={triggerDeleteAlert}
                title='Delete Incident'
                saveText='DELETE'
                cancelText='CANCEL'
                content={
                    <section className="delete-cam-dialog-content">
                        <header>Are you sure you want to delete this incident? </header>
                        <p>you will not be able to recover this incident</p>
                    </section>
                }
            />
        </>
    );
};

export default AlertsPage;
