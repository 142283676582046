import React, { forwardRef, ForwardRefRenderFunction, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import './AddCameraDetailsComponent.scss';
// import floorPlan from '../../assets/images/floor-plan.png';
import { useDispatch } from 'react-redux';
import { addCamera } from '../../sdk/redux/slices/cameraSlice';
import alertConfigService from '../../sdk/services/alertConfigService';
import cameraConfigService from '../../sdk/services/cameraConfigService';
import { CameraConfigType } from '../../sdk/types/cameraConfig.type';
import { percentageToScale } from '../../utils/common.util';
import { debounce } from '../../utils/debounce';
import CameraView from '../CameraView/CameraViewComponent';
import ZoomablePage from '../zoomable/ZoomablePage';
import ToastService from '../Toast/ToastService';

export type FormFieldsType = {
    cameraId: string;
    cameraName: string;
    vmsLiveFeedUrl: string;
    location: string;
    coordinateX: string;
    coordinateY: string;
    cameraAngle: number;
    fieldOfView: number;
};

interface IAddCameraDetails {
    onFormChange: (data: FormFieldsType) => void;
    onCameraAddedEvent: (data: CameraConfigType) => void;
    formValidationResultEvent: (result: boolean) => void;
}


// Define a ref type for the methods you want to expose
export interface AddCameraDetailsHandle {
    save: () => void;
}

const AddCameraDetails: ForwardRefRenderFunction<AddCameraDetailsHandle, IAddCameraDetails> = ({ onFormChange, onCameraAddedEvent, formValidationResultEvent }, ref) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<{ [key in keyof FormFieldsType]?: string }>({});
    const [formData, setFormData] = useState<FormFieldsType>({
        cameraId: '',
        cameraName: '',
        vmsLiveFeedUrl: '',
        location: '',
        coordinateX: '',
        coordinateY: '',
        cameraAngle: 0,
        fieldOfView: 0,
    });

    const validPattern = /[a-zA-Z0-9]/;

    // Debounced function for handling form change
    const debouncedOnFormChange = useCallback(
        debounce((data: FormFieldsType) => {
            onFormChange(data);
        }, 500),
        [onFormChange]
    );

    // Handle onBlur event to check for errors
    const handleBlur = (target: HTMLInputElement) => {
        const { name, value } = target;

        const readableName = name == 'cameraId' ? 'Camera ID' : (
            name == 'cameraName' ? 'Camera Name' : (
                name == 'vmsLiveFeedUrl' ? 'RTSP url' : (
                    name == 'location' ? 'Location' : ''
                )
            )
        )

        // Check if input is empty
        if (value.trim() === '') {
            setErrors(prevErrors => ({ ...prevErrors, [name]: `${readableName} cannot be empty` }));
        }
        // Check if input has only special characters
        else if (!validPattern.test(value)) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: `${readableName} should have at least one number or alphabet` }));
        }
        // Clear errors if valid
        else {
            setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
        }
    };

    // Handle changes in form fields
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Clear errors when typing
        handleBlur(e.target);
        // setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));

        const parsedValue = name === 'cameraAngle' || name === 'fieldOfView'
            ? Math.max(0, Math.min(parseInt(value, 10), 360))
            : value;
        setFormData(prevState => {
            const updatedFormData = { ...prevState, [name]: parsedValue };
            debouncedOnFormChange(updatedFormData);
            return updatedFormData;
        });
    };

    const validateForm = () => {
        const { cameraId, cameraName, vmsLiveFeedUrl, location, coordinateX, coordinateY, cameraAngle, fieldOfView } = formData;
        console.log('called', cameraId.trim() !== '' && validPattern.test(cameraId.trim()));
        const result = (
            (cameraId.trim() !== '' && validPattern.test(cameraId.trim())) &&
            (cameraName.trim() !== '' && validPattern.test(cameraName.trim())) &&
            (vmsLiveFeedUrl.trim() !== '' && validPattern.test(vmsLiveFeedUrl.trim())) &&
            (location.trim() !== '' && validPattern.test(location.trim())) &&
            coordinateX.trim() !== '' &&
            coordinateY.trim() !== '' &&
            cameraAngle.toString() !== '' &&
            fieldOfView.toString() !== '' &&
            cameraAngle >= 0 && cameraAngle <= 360 &&
            fieldOfView > 0 && fieldOfView <= 360
        );

        console.log('result: ', result);
        return result;
    };

    useEffect(() => {
        formValidationResultEvent(validateForm());
    }, [formData]);

    const handleAddCamera = async (formData: FormFieldsType) => {
        try {
            const payload: CameraConfigType = {
                ...formData,
                coordinateX: percentageToScale(formData.coordinateX),
                coordinateY: percentageToScale(formData.coordinateY),
                // vmsLiveFeedUrl: '',
                primaryImageUrl: ''
            };
            const addCameraResponse = await cameraConfigService.addCamera(payload);
            if (addCameraResponse) {
                dispatch(addCamera(addCameraResponse));
                onCameraAddedEvent(addCameraResponse);
                ToastService.showToast('Add camera', `Camera added successfully`, 'success', 3000);
            } else {
                ToastService.showToast('Add camera', `Failed to add camera`, 'error', 3000);
            }
            if (addCameraResponse?.id) {
                const addFixedAlerts = await alertConfigService.createFixedAlert(addCameraResponse.id);
                console.log('addFixedAlerts----->', addFixedAlerts);
            }
        } catch (error: any) {
            const { errorCode, errorInfo } = error?.response?.data;
            let errorMessage = errorInfo || 'Something went wrong';
            ToastService.showToast('Login failed', errorMessage, 'error');
        }
    }

    useImperativeHandle(ref, () => ({
        save: () => {
            handleAddCamera(formData);
        }
    }), [formData, onFormChange]);

    // Render a single input block
    const inputBlockRenderer = (
        labelTxt: string,
        inputType: 'text' | 'number',
        isMandatory: boolean,
        placeHolderTxt: string,
        isDisabled: boolean,
        name: keyof FormFieldsType,
        min?: number,
        max?: number
    ) => (
        <div className="input-block">
            <section className="input-fields">
                <label htmlFor={name}>
                    {labelTxt}{isMandatory && <mark>*</mark>}
                </label>
                <input
                    type={inputType}
                    placeholder={placeHolderTxt}
                    disabled={isDisabled}
                    name={name}
                    value={formData[name]}
                    onChange={handleChange}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleBlur(e.target)}
                    min={min}
                    max={max}
                />
            </section>
            {
                isMandatory && errors[name] && (
                    <p className="error-message">{errors[name]}</p> // Display error if exists
                )
            }
        </div>
    );

    // Effect to clean up any remaining debounced calls when the component unmounts
    useEffect(() => {
        return () => {
            debouncedOnFormChange.cancel();
        };
    }, [debouncedOnFormChange]);

    const handleComponentPositionChange = (position: { x: string; y: string }) => {
        console.log(`Updated/Created component position: x=${position.x}, y=${position.y}`);
        setFormData(prevState => {
            const updatedFormData = {
                ...prevState,
                coordinateX: position.x,
                coordinateY: position.y,
            };
            debouncedOnFormChange(updatedFormData);
            return updatedFormData;
        });
    }

    const draggableComponent = {
        component: (
            <CameraView
                cameraAngle={formData?.cameraAngle || 0}
                fieldOfView={formData?.fieldOfView || 0}
                showPin={false}
            />
        ),
        cameraAngle: formData.cameraAngle,
        fieldOfView: formData.fieldOfView
    }

    return (
        <section className="add-camera-details">
            <aside className="left-content">
                <header>Camera details</header>

                <section className="form-block">
                    <div className="form-content">
                        {inputBlockRenderer('Camera ID', 'text', true, 'Enter camera ID', false, 'cameraId')}
                        {inputBlockRenderer('Camera Name', 'text', true, 'Enter camera name', false, 'cameraName')}
                        {inputBlockRenderer('RTSP URL', 'text', true, 'Enter RTSP URL', false, 'vmsLiveFeedUrl')}
                        {inputBlockRenderer('Camera Location', 'text', true, 'Enter camera location', false, 'location')}
                        <div className="row">
                            <section className="column">
                                {inputBlockRenderer('x-coordinate', 'text', true, 'x-coordinate', true, 'coordinateX')}
                            </section>
                            <section className="column">
                                {inputBlockRenderer('y-coordinate', 'text', true, 'y-coordinate', true, 'coordinateY')}
                            </section>
                        </div>
                        <p>*Click on the map for entering camera location</p>
                        <div className="row">
                            <section className="column">
                                {inputBlockRenderer('Camera Angle', 'number', true, 'Enter angle', false, 'cameraAngle', 0, 360)}
                            </section>
                            <section className="column">
                                {inputBlockRenderer('Camera FoV', 'number', true, 'Enter FoV', false, 'fieldOfView', 0, 360)}
                            </section>
                        </div>
                    </div>
                </section>
            </aside>

            <article className="main-content">
                <ZoomablePage
                    components={[]}
                    showZoomControls={false}
                    isImageDraggable={false}
                    isCamCreationAllowed={true}
                    draggableComponent={draggableComponent}
                    onComponentPositionChange={handleComponentPositionChange} />
            </article>
        </section>
    );
};

export default forwardRef(AddCameraDetails);
