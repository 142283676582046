import { useEffect, useState } from "react";

export interface IDialogProps {
    title: string;
    content: JSX.Element;
    dialogMaxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    isSaveDisabled?: boolean;
    cancelText: string;
    saveText: string;
    onSave: (data: any) => void;
    onCancel: () => void;
}

// Define a custom hook for dialog handling
const useCustomDialogHandler = () => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [dialogData, setDialogData] = useState<any>(null);

    useEffect(() => {
        // console.log('dialogData::', dialogData);
    }, [dialogData])

    const handleOpenDialog = () => {
        setDialogData(null);
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    }
    const handleCancelDialog = () => {
        handleCloseDialog();
    };
    const handleSaveDialog = () => {
        // handleCloseDialog();
        return dialogData;
    };

    const handleSetDialogData = (data: any) => {
        setDialogData(data);
    }

    return { openDialog, dialogData, setDialogData, handleSetDialogData, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog };
};

export default useCustomDialogHandler;