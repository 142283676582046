import React, { useEffect, useRef, useState } from 'react';

interface WebRTCPlayerProps {
    src?: string;
    width?: number;
    height?: number;
}

const WebRTCPlayer: React.FC<WebRTCPlayerProps> = ({ width = 560, height = 315, src = "" }) => {
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [ws, setWs] = useState<WebSocket | null>(null);
    const wsUrl = 'wss://antmedia.workersafety.aiotdemos.com:5443/WorkerSafetyDev/qualcomm-edge-device-stream.webrtc';

    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement) return;

        const pc = new RTCPeerConnection();

        pc.ontrack = (event: RTCTrackEvent) => {
            videoElement.srcObject = event.streams[0];
        };

        pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
            if (event.candidate) {
                ws?.send(JSON.stringify({ candidate: event.candidate }));
            }
        };

        const websocket = new WebSocket(wsUrl);
        setWs(websocket);

        websocket.onopen = () => {
            console.log('WebSocket connection opened');
        };

        websocket.onmessage = async (event: MessageEvent) => {
            const message = JSON.parse(event.data);
            if (message.sdp) {
                await pc.setRemoteDescription(new RTCSessionDescription(message.sdp));
                if (message.sdp.type === 'offer') {
                    const answer = await pc.createAnswer();
                    await pc.setLocalDescription(answer);
                    websocket.send(JSON.stringify({ sdp: pc.localDescription }));
                }
            } else if (message.candidate) {
                await pc.addIceCandidate(new RTCIceCandidate(message.candidate));
            }
        };

        websocket.onerror = (error: Event) => {
            console.error('WebSocket error:', error);
        };

        websocket.onclose = () => {
            console.log('WebSocket connection closed');
            // setWs(null);
        };

        return () => {
            pc.close();
            websocket.close();
        };
    }, []);

    return (
        <>
            {/* <video ref={videoRef} controls autoPlay style={{ width: '100%', height: 'auto' }}></video> */}
            <iframe
                width={width}
                height={height}
                src={src}
                frameBorder="0"
                allowFullScreen
            />
        </>
    );
};

export default WebRTCPlayer;
