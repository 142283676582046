import { useState } from "react";
import CustomDialog from "../../components/CustomDialog/CustomDialogComponent";
import AlertBox from "../../components/AlertBox/AlertBoxComponent";
import { Notifications } from "@mui/icons-material";
import EventCountBar, { IEventCounter } from "../../components/EventCountBar/EventCountBarComponent";
import ExpandableList from "../../components/ExpandableList/ExpandableList";
import ExpandableAlertList from "../../components/ExpandableAlertList/ExpandableAlertList";
import { Divider } from "@mui/material";
import './UiElementsPage.scss';
import RippleEffect from "../../components/RippleEffect/RippleEffect";
import Quadrilateral from "../../components/Quadrilateral/QuadrilateralComponent";
import floorHotspotImage from '../../assets/images/floor-hotspot1.png';
import floorHotspotVideo from '../../assets/videos/floor-hotspot1.mp4';
import useCustomDialogHandler from "../../components/CustomDialog/useCustomDialogHandler";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckboxComponent";
import CustomSelect from "../../components/CustomSelect/CustomSelectComponent";
import CustomButton from "../../components/CustomButton/CustomButtonComponent";
import SvgIcon from "../../components/SvgIcons/SvgIconComponent";
import { AlertDetailsType } from "../../sdk/types/alert.type";
import QuadrilateralOverlay from "../../components/QuadrilateralOverlay/QuadrilateralOverlay";
import WebRTCPlayer from "../../components/WebRTCPlayer/WebRTCPlayer";

const eventCounterList: IEventCounter[] = [
    { count: 54, icon: 'safetyInfractions', name: 'Safety Infraction' },
    { count: 10, icon: 'ppeNoHardHat', name: 'PPE - No Hard Hat' },
    { count: 54, icon: 'ppeNoSafetyVest', name: 'PPE - No safty vest' },
    { count: 54, icon: 'ppeNoMask', name: 'PPE - No mask' },
    { count: 9, icon: 'fallDetection', name: 'Fall detection' },
    { count: 188, icon: 'proximityToRunningEquipment', name: 'Proximity to run equipment' },
];

const expandableitems = [
    {
        "camera": {
            "id": "93014231c176456999930a7438a88a4e",
            "cameraName": "camera1",
            "cameraId": "camera123",
            "location": "testtest",
            "coordinateX": 0.39709944,
            "coordinateY": 0.73963517,
            "cameraAngle": 0,
            "fieldOfView": 0,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521777983,
            "lastModifiedDate": 1724521777983
        },
        "id": "2aac45fd4f404110a471669701cbe498",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Hard Hat",
        "alertFk": "35615fe4c69e4e87bf889d5b67d031b7",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "93014231c176456999930a7438a88a4e",
            "cameraName": "camera1",
            "cameraId": "camera123",
            "location": "testtest",
            "coordinateX": 0.39709944,
            "coordinateY": 0.73963517,
            "cameraAngle": 0,
            "fieldOfView": 0,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521777983,
            "lastModifiedDate": 1724521777983
        },
        "id": "5d84ce0e7e0d4690bf8c436ca1b6490a",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Safety Vest",
        "alertFk": "fcf3f86972314334992e23f2276d43c2",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "6e2b704fe78f418f8f77bbaec10ef056",
            "cameraName": "camera2",
            "cameraId": "camera2",
            "location": "test2",
            "coordinateX": 0.39212707,
            "coordinateY": 0.51051825,
            "cameraAngle": 180,
            "fieldOfView": 0,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521817801,
            "lastModifiedDate": 1724521817801
        },
        "id": "b958290b6bd44aacba9ed65f7245a30d",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Safety Vest",
        "alertFk": "cca76b73e70141a18e52bce9939b88ae",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "6e2b704fe78f418f8f77bbaec10ef056",
            "cameraName": "camera2",
            "cameraId": "camera2",
            "location": "test2",
            "coordinateX": 0.39212707,
            "coordinateY": 0.51051825,
            "cameraAngle": 180,
            "fieldOfView": 0,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521817801,
            "lastModifiedDate": 1724521817801
        },
        "id": "baac6bfd242446c89fd049481fce0f64",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Hard Hat",
        "alertFk": "df6b1adb0bbd48c58ac798d098839cd8",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "a7684f60f5a94d8681da77c05b30208e",
            "cameraName": "test3",
            "cameraId": "test3",
            "location": "test3",
            "coordinateX": 0.4642265,
            "coordinateY": 0.36322877,
            "cameraAngle": 90,
            "fieldOfView": 0,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521926351,
            "lastModifiedDate": 1724521926351
        },
        "id": "c65742223d1e40ecac5e41da82d4fd2b",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Hard Hat",
        "alertFk": "4b22c3c0e7174d089ef6b6d0e54e2dad",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "a7684f60f5a94d8681da77c05b30208e",
            "cameraName": "test3",
            "cameraId": "test3",
            "location": "test3",
            "coordinateX": 0.4642265,
            "coordinateY": 0.36322877,
            "cameraAngle": 90,
            "fieldOfView": 0,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521926351,
            "lastModifiedDate": 1724521926351
        },
        "id": "8ea6c4a0ae8d4c2d96b697659854ddfb",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Safety Vest",
        "alertFk": "d123496e575243449f961bd64e4e45a4",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "1e44e4fe8f414f4a8ce1f9d755abf109",
            "cameraName": "test4",
            "cameraId": "test4",
            "location": "test4",
            "coordinateX": 0.5922652,
            "coordinateY": 0.6659905,
            "cameraAngle": 45,
            "fieldOfView": 90,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521974275,
            "lastModifiedDate": 1724521974275
        },
        "id": "a80bc5a2dfd3476fa382955513a7cfff",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Safety Vest",
        "alertFk": "6a7f6ebe3fb44ffb91b293b50b1fe060",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    },
    {
        "camera": {
            "id": "1e44e4fe8f414f4a8ce1f9d755abf109",
            "cameraName": "test4",
            "cameraId": "test4",
            "location": "test4",
            "coordinateX": 0.5922652,
            "coordinateY": 0.6659905,
            "cameraAngle": 45,
            "fieldOfView": 90,
            "vmsLiveFeedUrl": "",
            "primaryImageUrl": "",
            "createdBy": "Admin",
            "updatedBy": "Admin",
            "createdDate": 1724521974275,
            "lastModifiedDate": 1724521974275
        },
        "id": "376a79317a4646839ea916a52f66ef83",
        "alertCategory": "fixed",
        "alertType": "required_accessories",
        "alertName": "PPE - No Hard Hat",
        "alertFk": "dd7627860ff3449e93b514649f6b8e1d",
        "resolved": false,
        "deleted": false,
        "videos": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/04/file_example_MP4_480_1_5MG.mp4"
        ],
        "images": [
            "https://file-examples.com/storage/fe45dfa76e66c6232a111c9/2017/10/file_example_JPG_100kB.jpg",
            "https://filesampleshub.com/download/image/jpg/sample1.jpg",
            "https://farm2.staticflickr.com/1533/26541536141_41abe98db3_z_d.jpg",
            "https://farm4.staticflickr.com/3075/3168662394_7d7103de7d_z_d.jpg"
        ]
    }
];


const UiElementsPage: React.FC = () => {

    const [open, setOpen] = useState<boolean>(false);
    const [coordinates, setCoordinates] = useState<any>([
        {
            "x": 8,
            "y": 101.40277862548828
        },
        {
            "x": 481,
            "y": 293.4027786254883
        },
        {
            "x": 525,
            "y": 498.4027786254883
        },
        {
            "x": 340,
            "y": 476.4027786254883
        }
    ]);
    const { openDialog, handleOpenDialog, handleCloseDialog, handleCancelDialog, handleSaveDialog } = useCustomDialogHandler();

    const [selectedOption, setSelectedOption] = useState<string>('');

    const handleSelectChange = (option: string) => {
        setSelectedOption(option);
    };

    const handleOpen = () => {
        handleOpenDialog();
    }

    const handleCoordinatesChange = (points: { x: number; y: number }[]) => {
        console.log('Updated Coordinates:', points);
        // You can perform further actions with the updated coordinates here
    };

    return (
        <section className="ui-elements-page">

            <header>WebRTCPlayer</header>

            <section className="content">
                <WebRTCPlayer />
            </section>


            <header>QuadrilateralOverlay</header>

            <section className="content">
                <QuadrilateralOverlay
                    mediaUrl={floorHotspotVideo}
                    isVideo={true}
                    canDrag={true}
                    showLines={true}
                    onPointsChange={(points) => console.log(points)}
                // isVideo={true}
                />
            </section>

            <Divider />

            <header>Toggle Checkbox</header>
            <section className="content">
                <CustomSelect
                    options={['Option 1', 'Option 2', 'Option 3']}
                    selectedOption={selectedOption}
                    onChange={handleSelectChange}
                />
                <p>Selected option: {selectedOption}</p>
            </section>

            <Divider />

            <header>Toggle Checkbox</header>
            <section className="content">
                <CustomCheckbox checked={true} onChange={(e) => (console.log(e))} />
            </section>

            <Divider />

            <header>Quadrilateral video</header>
            <section className="content">
                <Quadrilateral
                    mediaType="video"
                    pointsVisible={false}
                    mediaSrc={floorHotspotVideo}
                    defaultCoordinates={coordinates}
                    onCoordinatesChange={handleCoordinatesChange} />
            </section>

            <Divider />

            <header>Quadrilateral image</header>
            <section className="content">
                <Quadrilateral
                    mediaType="image"
                    pointsVisible={true}
                    mediaSrc={floorHotspotImage}
                    onCoordinatesChange={handleCoordinatesChange} />
            </section>

            <Divider />

            <header>Buttons</header>
            <section className="content">
                <CustomButton
                    icon="settings" // Material-UI icon name
                // contentDisplay='only-icon-or-text'
                // type="iconOnly"
                // onClick={() => console.log('Icon Only Button Clicked')}
                />

                <CustomButton
                    icon="delete"
                    variant="outlined"
                />

                {/* Contained button with only text */}
                <CustomButton
                    text="Contained"
                />

                {/* Outlined button with only text */}
                <CustomButton
                    text="Outlined"
                    variant="outlined"
                />

                {/* Contained button with both text and icon */}
                <CustomButton
                    text="Star"
                    icon="star"
                    iconPosition="start"
                />

                {/* Outlined button with both text and icon */}
                <CustomButton
                    text="Star"
                    icon="chair"
                    variant="outlined"
                    iconPosition="start"
                />

                <CustomButton
                    text="Warning Button"
                    icon="warning"
                    variant="contained"  // You can use 'outlined' as well
                    btnType="warning"
                />

                <CustomButton
                    text="Success Button"
                    icon="check_circle"
                    variant="contained"  // You can use 'outlined' as well
                    btnType="success"
                />

                <CustomButton
                    text="Error Button"
                    icon="error"
                    variant="contained"  // You can use 'outlined' as well
                    btnType="error"
                />
            </section>

            <Divider />

            <header>Dialog box</header>
            <section className="content">
                <CustomButton
                    text="Open dialog"
                    icon="chair"
                    variant="contained"
                    iconPosition="start"
                    onClick={handleOpen}
                />

                <CustomDialog
                    headerLabel='Header label'
                    open={openDialog}
                    content={<div>Dynamic Content Goes Here</div>}
                    dialogMaxWidth='md'
                    cancelText="Cancel"
                    saveText="Save"
                    onClose={handleCloseDialog}
                    onCancel={handleCancelDialog}
                    onSave={handleSaveDialog}
                />
            </section>

            <Divider />

            <header>Alert box</header>
            <section className="content">
                <div style={{ width: '284px' }}>

                    <AlertBox
                        headerLabel="Unattended"
                        subHeaderLabel="Production zone"
                        subHeaderIcon={<SvgIcon name='location' />}
                        footerLabel="Cam ID - #2"
                        timestamp="07-22-2024 | 12:09 AM"
                        showStatus={true}
                        showActionSection={true}
                        showViewDetailsBtn={true}
                        thumbnails={{
                            image: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg',
                            video: 'https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg'
                        }}
                        onAlertViewDetailsClick={function (): void {
                            // throw new Error("Function not implemented.");
                            console.log('Alert box:: ',);
                        }}
                        onResolveBtnClick={() => console.log()}
                        onDeleteBtnClick={() => console.log()}
                    />
                </div>
            </section>

            <Divider />

            <header>Event count bar</header>
            <section className="content">
                <EventCountBar countList={eventCounterList} />
            </section>

            {/* <header>Expandable list</header>
            <section className="content">
                <div style={{ width: '320px' }}>
                    <ExpandableList items={expandableitems} />
                </div>
            </section> */}

            <Divider />

            <header>Expandable alert list</header>
            <section className="content">
                <div style={{ width: '320px' }}>
                    <ExpandableAlertList
                        items={expandableitems}
                        alertToShowViewDetails={null}
                        showInPopupDesign={false}
                        showStatus={true}
                        onSelectingAlertToShowAction={(alert: AlertDetailsType) => console.log(alert)}
                        onAlertViewDetailsClick={function (): void {
                            // throw new Error("Function not implemented.");
                            console.log('ExpandableAlertList:: ', expandableitems);
                        }}
                        onResolveBtnClick={(alert) => console.log(alert)}
                        onDeleteBtnClick={(alert) => console.log(alert)}
                        onImageThumbnailClick={(alert) => console.log(alert)}
                        onVideoThumbnailClick={(alert) => console.log(alert)}
                    />
                </div>
            </section>

            <Divider />

            <header>Ripple</header>
            <section className="content">
                <RippleEffect as="button" className="ripple-button">Button</RippleEffect>
                <RippleEffect as="a" href="#" className="ripple-link">Link</RippleEffect>
                <RippleEffect as="span" className="ripple-span">Span</RippleEffect>
                <RippleEffect as="figure" className="ripple-figure">
                    <img src="image.jpg" alt="Example" />
                    <figcaption>Figure</figcaption>
                </RippleEffect>
            </section>


        </section>
    );
}

export default UiElementsPage;